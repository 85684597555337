.footer {
    margin-top: 1rem;
    padding: 1rem;
    color: black;
    background-color: #f0f0f0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }

.footer .left {
    float: left
}

.footer .right {
    float: right
}