.quality_category_title {
  font-weight: bold;
  font-size: large;
}

.quality_finding_description {
  background-color: #ffe359;
  font-size: medium;
}

.artifact_path {
  font-weight: bolder;
}

pre code {
  display: block;
  background: none;
  white-space: pre;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  max-width: 100%;
  min-width: 100px;
  padding: 0;
}

pre code .highlighted_line {
    background-color: #fff2ac;
}

pre code .highlighted_line {
  background-color: #fff2ac;
}

.highlight {
  background-color: #fff2ac;
  background-image: linear-gradient(to right, #ffe359 0%, #fff2ac 100%);
}

.critical {
  color: purple
}

.high {
  color: red
}

.medium {
  color: orange
}

.low {
  color: green
}

.unknown {
  color: lightgray
}